import axiosIns from '@/libs/axios'

export default {
  getAllCampuses: (() => axiosIns.get('accounting/due-report/campuses')),
  getAllSchools: (() => axiosIns.get('accounting/due-report/schools')),
  getAllClasses: (() => axiosIns.get('accounting/due-report/classes')),
  getClassesBySchoolId: (id => axiosIns.get(`accounting/due-report/classes-by-school-id/${id}`)),
  getAllSection: ((classId, shiftId, sessionId) => axiosIns.get(`accounting/due-report/academic-section/${classId}/${shiftId}/${sessionId}`)),
  getPaymentCycleByClassId: ((classId, sessionId) => axiosIns.get(`accounting/due-report/payment-cycle/${classId}/${sessionId}`)),
  getSessionByClassId: (classId => axiosIns.get(`accounting/due-report/academic-session/${classId}`)),
  getDueReport: (payload => axiosIns.post('accounting/due-report', payload)),
}
